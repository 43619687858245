/** @format */

import React, { useEffect, useState } from "react";
import Paragraph from "./Paragraph";
import Volunteer from "components/displayData/Volunteer";

function SchoolMethodology() {
  const [school, setSchool] = useState([]);
  const fetchPosts = async () => {
    const response = await fetch(
      "https://api.communitymonitoring.org/api/external/sites-monitored"
    );
    const data = await response.json();

    setSchool(data.cbms);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <>
      <div className='flex flex-col items-center w-full h-full pb-10'>
        {/* header */}
        <h1 className=' text-[24px]  px-4 lg:px-0 text-left dark:text-white lg:text-[30px] uppercase text-grayDark2 lg:text-center font-bold'>
          The methodology of{" "}
          <span className=''>
            {" "}
            <br /> Community Based Monitoring of School
          </span>
        </h1>
        {/* main content */}
        <div className='px-4 mt-4 lg:px-10'>
          <Paragraph
            line='h-[100px]'
            title='Community Mobilization'
            description='Committed community involvement is pivotal for the successful implementation of any CBM Program. With permission and support from local authorities, our CBM team reaches out to selected communities and explains the need for the CBM-S program and its aims and outcomes. Community members are then encouraged to consider volunteering as community monitors. At a subsequent meeting, the community elects a qualified person as an Integrity Volunteer (or Community Representative) to monitor a local school or education service. The volunteer should live close to the school and be literate, of good reputation within the community, and able to give several hours each week to monitoring tasks.'
          />
          <Paragraph
            line='h-[100px]'
            title='Training of Integrity Volunteers '
            description='To effectively monitor education services, Integrity Volunteers must be equipped with the necessary skills. Therefore, following their election, the Integrity Volunteers attend training and orientation sessions to prepare them for the task. Training covers basic concepts of governance, awareness, community mobilization, monitoring, and problem-solving. The Integrity Volunteers also gain an understanding of how to conduct surveys and use monitoring tools, including the MaktabSar (schools monitoring) mobile application. '
          />
          <Paragraph
            line='h-[100px]'
            title='Baseline Survey'
            description='It is imperative to thoroughly understand ground-level experience before starting an intervention. The baseline survey enables the implementers (in this case, Integrity Watch) to draft suitable strategies to measure the effectiveness and impact of the CBM-S intervention. Integrity Volunteers use specifically designed questionnaires to collect information from beneficiaries of education services. Collected data are then fed into Integrity Watch’s database and baseline survey reports are produced. 
          '
          />
          <Paragraph
            line='h-[100px]'
            title='Monitoring of Schools and Participation in SMS meetings'
            description='Monitoring of schools and overseeing the proper functioning of School Management Shuras (SMS) are the core objectives of the CBM-S Program. The Integrity Volunteer visit the school at least twice a week and records findings on the MaktabSar mobile application or hard-copy monitoring forms. The Integrity Volunteer also attends monthly SMS meetings to report findings and seek immediate solutions, as well as to monitor SMS performance.'
          />
          <Paragraph
            line='h-[100px]'
            title='Data Reporting'
            description='The provincial office oversees data reporting. Data from weekly and quarterly monitoring forms are manually transferred into the database, if not already (automatically) uploaded via the monitoring app. A monthly feedback sheet is prepared from the recorded information and submitted to the school management and community representatives.'
          />
          <Paragraph
            line='h-[100px]'
            title='Community Feedback Meeting'
            description='Each month the Integrity Volunteer organizes a Community Feedback Meeting to brief community members about CBM-S activities and findings. Together the members consider opportunities to resolve problems at the school with local-level resources and through further public engagement.'
          />

          <Paragraph
            line='h-[100px]'
            title='Integrity Volunteers’ Meeting '
            description='Integrity Volunteers from different communities meet together once a month. Hosted by IWA or an implementing partner, this sector-specific meeting allows Integrity Volunteers to encourage one another, share experiences, exchange ideas, determine common challenges, and jointly seek solutions.'
          />
          <Paragraph
            line='h-[100px]'
            title='Sectoral Monitoring Group (SMG)'
            description='This is a particularly important step of the CBM-S program. The SMG comprises a very wide range of stakeholders, including IWA or implementing partner staff, Integrity Volunteers, education officials, heads of SMSs, and community leaders and other representatives, to discuss problems that have not been resolved at the school or community level. The meetings take place twice quarterly and also address immediate problems in the education sector at the provincial level. '
          />

          <Paragraph
            title='Endline Survey'
            description='This program concludes with a survey to gauge CBM-S program impact and effectiveness. The outcome of this survey is used as input for evaluating the program’s success overall. '
            description2='Following what is usually a two-year facilitation period, IWA hands over coordination of the CBM-S program to the community, though remains available to provide technical assistance where needed.
          '
          />
          <div className='flex items-center justify-center w-full my-10'>
            <div className='w-1/2 bg-grayDark  h-[1px]'></div>
          </div>
          <Paragraph
            description={`Since 2014, local communities across eight Afghan provinces have monitored ${school["sites-monitored"]} schools. The program has identified ${school["problems"]} issues, with a fix-rate of ${school["fixrate"]} per cent. Achievements include improvements to school hygiene, provision of drinking water, improvements in attendance of teachers and students, activation of the School Management Shura, supply of textbooks, and the construction of boundary walls and even school buildings, in exceptional cases. Though unresolved problems remain common, the CBM-S program is demonstrating efficiency and impact in communities located in some of the world’s most challenging environments.`}
          />
        </div>
        <p></p>
      </div>
      {/* data from communitymonitoring */}
      <div className='h-full max-w-screen-xl mx-auto mt-8'>
        <Volunteer
          volunteers={school.volunteers}
          problemsIdentified={school.problems}
          problemsSolved={school["problems-solved"]}
          sitesMonitored={school["sites-monitored"]}
        />
      </div>
    </>
  );
}

export default SchoolMethodology;
