/** @format */

import IntegrityColor from "components/icons/IntegrityColor";
import Navbars from "components/navbar/Navbars";
import React from "react";
import workHeader from "assets/Where we work/wherewework.png";

function Header() {
  return (
    <div className='bg-colorWhite dark:bg-darkHeader w-full h-full lg:h-[410px] '>
      <Navbars
        textColor={"text-textPrimary"}
        bg='bg-[#FAFAFA] dark:bg-darkHeader'
        logo={<IntegrityColor />}
      />
      {/* headeer */}
      <div className='relative flex flex-col-reverse max-w-screen-xl pb-4 pl-8 pr-4 mx-auto mt-5 dark:bg-darkHeader lg:pb-0 lg:px-0 lg:grid lg:mt-20 lg:grid-cols-10'>
        <div className='mt-16 text-left lg:col-span-5 lg:mt-0'>
          <h1 className='text-grayPrimary uppercase  text-[35px] font-bold'>
            where we work
          </h1>
          <p className='text-grayDark3 dark:text-white font-calibrilight text-[22px] text-lg mt-4'>
            Integrity Watch works in Afghanistan. We will also work in any
            country where we can share our learning and expertise and make an
            impact. Our purpose is to empower citizens and civil society actors
            to act with integrity and demand transparency and accountability
            from those in positions of power.
          </p>
        </div>
        <div className='flex justify-center lg:justify-end lg:col-span-5'>
          <div className='h-[200px] lg:absolute lg:top-[70px] lg:right-0 '>
            <img src={workHeader} className='w-full h-full mt-2 ' alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
