/** @format */

import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Content() {
  const [job, setJob] = useState({});
  const navigate = useNavigate();

  const location = useLocation();
  const jobId = location.pathname.split("/")[3];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/jobs/${jobId}`
        );
        setJob(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [jobId]);

  const isExpired = new Date() > new Date(job.closing);

  const [joblimit, setJoblimit] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/jobs/limitjob`
        );
        setJoblimit(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="w-full h-full mt-10">
      {/* header */}
      <h1 className="text-[40px] font-bold pt-10 dark:text-white text-textSecondary ">
        {job.title}
      </h1>
      {/* main */}
      <div className="mt-4">
        <div className="flex items-center justify-between mb-2 sm:justify-start gap-7">
          <h1 className="font-bold text-xl dark:text-white  w-[170px] md:w-[230px] text-grayPrimary md:text-[24px] ">
            Date Posted
          </h1>
          <h1 className="text-grayPrimary dark:text-white font-calibriregular text-xl md:text-[24px] ">
            {moment(job.posted).format("MM DD YYYY")}{" "}
          </h1>
        </div>
        <div className="flex items-center justify-between mb-2 sm:justify-start gap-7">
          <h1 className="font-bold dark:text-white w-[170px] md:w-[230px] text-grayPrimary text-xl md:text-[24px] ">
            Closing Date
          </h1>
          <h1 className="text-grayPrimary dark:text-white font-calibriregular text-xl md:text-[24px] ">
            {" "}
            {isExpired ? "Expired" : moment(job.closing).format("MM DD YYYY")}
          </h1>
        </div>
        <div className="flex items-center justify-between mb-2 sm:justify-start gap-7">
          <h1 className="font-bold dark:text-white w-[170px] md:w-[230px] text-grayPrimary text-xl md:text-[24px] ">
            Number of Vocancy
          </h1>
          <h1 className="text-grayPrimary dark:text-white font-calibriregular text-xl md:text-[24px] ">
            {job.vacancy}
          </h1>
          {/* push */}
        </div>
        <div className="flex items-center justify-between mb-2 sm:justify-start gap-7">
          <h1 className="font-bold w-[170px] md:w-[230px] dark:text-white text-grayPrimary text-xl md:text-[24px] ">
            Reference
          </h1>
          <h1 className="text-grayPrimary dark:text-white font-calibriregular text-xl md:text-[24px] ">
            {job.reference}
          </h1>
        </div>
        <div className="flex items-center justify-between mb-2 sm:justify-start gap-7">
          <h1 className="font-bold w-[170px] md:w-[230px] dark:text-white text-grayPrimary text-xl md:text-[24px] ">
            Work Type
          </h1>
          <h1 className="text-grayPrimary text-xl dark:text-white font-calibriregular md:text-[24px] ">
            {job.worktype}{" "}
          </h1>
        </div>
        <div className="flex items-center justify-between mb-2 sm:justify-start gap-7">
          <h1 className="font-bold w-[170px] md:w-[230px] dark:text-white text-grayPrimary text-xl md:text-[24px] ">
            Gender
          </h1>
          <h1 className="text-grayPrimary dark:text-white font-calibriregular text-xl md:text-[24px] ">
            {job.gender}{" "}
          </h1>
        </div>
        <div className="flex items-center justify-between mb-2 sm:justify-start gap-7">
          <h1 className="font-bold w-[170px] md:w-[230px] dark:text-white text-grayPrimary text-xl md:text-[24px] ">
            Nationality
          </h1>
          <h1 className="text-grayPrimary text-xl dark:text-white font-calibriregular md:text-[24px] ">
            {job.nationality}{" "}
          </h1>
        </div>
        <div className="flex items-center justify-between mb-2 sm:justify-start gap-7 ">
          <h1 className="font-bold w-[170px] md:w-[230px] dark:text-white text-grayPrimary text-xl md:text-[24px] ">
            Functional Area
          </h1>
          <h1 className="text-grayPrimary dark:text-white font-calibriregular text-xl md:text-[24px] ">
            {job.functionarea}
          </h1>
        </div>
        <div className="flex items-center justify-between mb-2 sm:justify-start gap-7">
          <h1 className="font-bold w-[170px] md:w-[230px] dark:text-white text-grayPrimary text-xl md:text-[24px] ">
            Contract Duration
          </h1>
          <h1 className="text-grayPrimary dark:text-white font-calibriregular text-xl md:text-[24px] ">
            {job.constractarea}{" "}
          </h1>
        </div>
        <div className="flex items-center justify-between mb-2 sm:justify-start gap-7">
          <h1 className="font-bold dark:text-white text-lg w-[170px] md:w-[230px] text-grayPrimary md:text-[24px] ">
            Years of Experience
          </h1>
          <h1 className="text-grayPrimary dark:text-white font-calibriregular text-xl md:text-[24px] ">
            {job.experience} Years
          </h1>
        </div>
        <div className="flex items-center justify-between mb-2 sm:justify-start gap-7">
          <h1 className="font-bold dark:text-white w-[170px] md:w-[230px] text-grayPrimary text-xl md:text-[24px] ">
            Contract Extension
          </h1>
          <h1 className="text-grayPrimary dark:text-white font-calibriregular text-xl md:text-[24px] ">
            {" "}
            {job.extension}{" "}
          </h1>
        </div>
        <div className="flex items-center justify-between mb-2 sm:justify-start gap-7">
          <h1 className="font-bold dark:text-white w-[190px] md:w-[230px] text-grayPrimary text-xl md:text-[24px] ">
            Required Languages
          </h1>
          <h1 className="text-grayPrimary dark:text-white font-calibriregular text-xl md:text-[24px] ">
            {job.languages}
          </h1>
        </div>
        {/* description */}
        <div className="h-fit">
          <h1 className="md:text-[30px] text-[24px] my-5 dark:text-white font-bold  text-textSecondary ">
            Job Description
          </h1>
          <div
            className="text-xl md:text-[24px] dark:text-white font-calibriregular text-grayDark3"
            dangerouslySetInnerHTML={{ __html: job.description }}
          />
        </div>
        {/* responsiblires */}
        <div className="">
          <h1 className="text-[24px] md:text-[30px] dark:text-white my-5 font-bold  text-textSecondary ">
            Duties & Responsiblites
          </h1>

          <div
            className="text-xl md:text-[24px] dark:text-white font-calibriregular text-grayDark3"
            dangerouslySetInnerHTML={{ __html: job.duties }}
          />
        </div>
        {/* job requirement */}
        <div className="">
          <h1 className="text-[24px] md:text-[30px] dark:text-white my-5 font-bold  text-textSecondary ">
            Job Requirement
          </h1>
          <div
            className="text-xl md:text-[24px] dark:text-white font-calibriregular text-grayDark3"
            dangerouslySetInnerHTML={{ __html: job.requirement }}
          />
        </div>
        {/* Work Exprience */}
        <div className="">
          <h1 className="text-xl md:text-[30px] dark:text-white my-5 font-bold  text-textSecondary ">
            Work Experience
          </h1>
          <div
            className="text-xl md:text-[24px] dark:text-white font-calibriregular text-grayDark3"
            dangerouslySetInnerHTML={{ __html: job.workexperience }}
          />
        </div>
        {/* Skills */}
        <div className="">
          <h1 className="text-[24px] md:text-[30px] dark:text-white my-5 font-bold  text-textSecondary ">
            Skills
          </h1>
          <div
            className="text-xl md:text-[24px] dark:text-white font-calibriregular text-grayDark3"
            dangerouslySetInnerHTML={{ __html: job.skills }}
          />
        </div>
        {/* Language */}
        <div className="">
          <h1 className="text-[24px] md:text-[30px] dark:text-white my-5 font-bold  text-textSecondary ">
            Languages
          </h1>
          <p className="text-xl md:text-[24px] dark:text-white text-grayPrimary ">
            {job.languages}
          </p>
        </div>
        {/* job location */}
        <div className="">
          <h1 className=" text-[24px] md:text-[30px] dark:text-white my-5 font-bold  text-textSecondary ">
            Job Location
          </h1>
          <p className="text-xl md:text-[20px] dark:text-white text-grayPrimary ">
            {job.location}
          </p>
        </div>
        {/* Guidline */}
        <div className="">
          <h1 className="text-[24px] md:text-[30px] dark:text-white my-5 font-bold  text-textSecondary ">
            Submission guideline:
          </h1>
          <div
            className="text-xl md:text-[24px] dark:text-white font-calibriregular text-grayDark3"
            dangerouslySetInnerHTML={{ __html: job.guidline }}
          />
        </div>
      </div>

      <div className="w-full px-10 mt-20 overflow-x-scroll md:overflow-hidden">
        <h1 className="font-banscrift dark:text-white mb-4 font-bold text-[#455A64] text-[36px] ">
          Latest jobs
        </h1>
        <table className="w-[700px] md:w-full !overflow-x-scroll  ">
          <thead className="w-full"></thead>
          <tbody>
            {joblimit.map((limit) => (
              <tr
                key={limit.id}
                className="w-full pl-2 md:px-2 text-[24px] dark:hover:bg-dark hover:bg-gray-100 text-grayPrimary mt-1 hover:text-textSecondary hover:cursor-pointer font-calibriregular  transition-all dark:text-white duration-300"
                onClick={() => navigate(`/announcement/jobs/${limit.id}`)}
              >
                <td className="ml-2 rounded-l-full"> {limit.id} </td>
                <td className="line-clamp-1">{limit.title}</td>
                <td className="">{limit.location} </td>

                <td className="">
                  {" "}
                  {moment(limit.posted).format("MM DD YYYY")}{" "}
                </td>
                <td className="rounded-r-full">
                  {" "}
                  {/* {moment(limit.closing).format("MM DD YYYY")}{" "} */}
                  {moment(new Date()).format("MM DD YYYY") >
                  moment(limit.closing).format("MM DD YYYY")
                    ? "Expired"
                    : moment(limit.closing).format("MM DD YYYY")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <table className='w-[600px] md:w-full !overflow-x-scroll  '>
          <thead className='w-full'></thead>
          <tbody>
            <tr className='w-full pl-3 md:px-2 text-[24px] dark:hover:bg-dark hover:bg-gray-100 text-grayPrimary mt-1 hover:text-textSecondary hover:cursor-pointer font-calibriregular  transition-all dark:text-white duration-300'>
              <td className='ml-2 rounded-l-full'> 1 </td>
              <td className='line-clamp-1'>updated new web</td>
              <td className=''>kabul, afghanistan </td>

              <td className=''> 2023/2/2</td>
              <td className='rounded-r-full'> 2023/3/3</td>
            </tr>
          </tbody>
        </table> */}
      </div>
    </div>
  );
}

export default Content;
