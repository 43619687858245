/** @format */
import IntegrityColor from "components/icons/IntegrityColor";
import Navbars from "components/navbar/Navbars";
import Stories from "view/pages/home/components/Stories";
import Header from "./components/Header";
import Monitoring from "./components/Monitoring";
const WhatWeDo = () => {
  return (
    <div className="w-full h-full pb-10 bg-white dark:bg-darkMain">
      <div className="bg-[#FAFAFA] dark:bg-darkHeader">
        <Navbars
          bg="bg-[#FAFAFA] dark:!bg-darkHeader"
          textColor={"text-textPrimary"}
          logo={<IntegrityColor />}
        />
        <Header />
      </div>
      <div className="lg:max-w-screen-lg px-4 lg:px-0 text-center dark:!bg-darkMain h-full mt-10 lg:!mt-24  mx-auto">
        <p className="text-grayDark2 font-calibrilight dark:text-white font-normal text-[22px] lg:text-[20px] mt-6 lg:mt-8 ">
          Community-Based Monitoring (CBM) is one of the most successful and
          sustainable models for boosting collective action, fostering public
          engagement, tackling corruption, and promoting integrity in the
          delivery of public services. The participatory structures we support
          in CBM are fully geared towards community empowerment. They include
          community education on the negative effects of corruption; the
          mobilization and training of citizens to monitor local services and
          projects; and the facilitating of community-led advocacy and
          problem-solving. Community-Based Monitoring programs thereby draw
          extensively on social capital and are community led and owned.
        </p>
        <p className="text-grayDark2 font-calibrilight dark:text-white font-normal text-[22px] lg:text-[20px] mt-6 lg:mt-8 ">
          The implementation of CBM requires permissions and cooperation from
          the relevant Ministry (education, health, etc) and also from local
          authorities within each targeted province. This working approach
          ensures the greatest possible buy-in at the community level. To find
          out more about CBM program methodology and structures, and how these
          promote quality and transparency in the delivery of public services,
          please click on subject areas of interest below.
        </p>
      </div>
      {/* Inside of Monitoring component threr are components for Schoo & Health tab also the methodology compoents */}
      <div className="lg:max-w-screen-xl dark:!bg-darkMain h-full mt-10 lg:!mt-24  mx-auto">
        <Monitoring />
      </div>
      {/* <div className='w-full h-full max-w-screen-xl px-4 mx-auto lg:px-0'>
        <img src={chart} className='w-full h-full' alt='chart' />
      </div> */}

      <div className="h-full">
        <Stories />
      </div>
    </div>
  );
};
export default WhatWeDo;
