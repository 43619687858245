/** @format */

import React from "react";
import headerphoto from "assets/What we do/header.png";
function Header() {
  return (
    //hello
    <div className="flex flex-col-reverse w-full mx-auto dark:bg-darkHeader lg:grid lg:grid-cols-10 lg:max-w-screen-xl pb-14 ">
      <div className="pl-10 pr-4 lg:px-0 lg:col-span-4">
        <h1 className="uppercase text-grayPrimary font-bold text-[24px] lg:text-[30px] mt-8 lg:mt-14  ">
          what we do
        </h1>
        <p className=" text-[26px] font-calibriregular dark:text-white lg:text-[40px] leading-[45px] mt-4 font-bold text-textPrimary ">
          We use Community-Based Monitoring to encourage active citizenship and
          community mobilization.
        </p>
        <p className="text-grayDark font-calibrilight dark:text-white font-normal text-[22px] lg:text-[22px] leading-8 mt-6 lg:mt-8 ">
          We encourage active citizenship and community mobilization through
          implementation of Community-Based Monitoring in close collaboration
          with our Local Partners.
        </p>
      </div>
      <div className="flex items-center justify-center lg:col-span-6 lg:justify-end">
        <img
          src={headerphoto}
          className=" w-[310px] h-[290px] md:w-[360px] md:h-[320px] lg:w-fit  lg:h-[500px] bg-cover bg-no-repeat"
          alt=""
        />
      </div>
    </div>
  );
}

export default Header;
